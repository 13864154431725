<template>
  <section>
    <Toast />
    <div class="bg-white my-2 rounded-md p-4">
      <div class="w-full flex items-center justify-between mb-8">
        <div class="flex gap-2 items-center">
          <i class="pi pi-list"></i>
          <p class="font-bold text-xl">Cargue de documentos relación mipres con CUM a medicamentos</p>
        </div>
        <Button @click="displayFilePond = true" label="Cargar documentos" severity="success" icon="pi pi-upload" style="height: fit-content" />
      </div>
      <TabView>
        <TabPanel header="Datos comparativo de Cod Mipres con Cod MX">
          <div class="my-4">
            <p class="font-medium italic">Fecha de la ultima actualización {{ datosComparativosMxMipres.length ? dayjs(datosComparativosMxMipres[0].createdAt).format('DD-MM-YYYY HH:mm A') : '' }}</p>
          </div>
          <DataTable
            v-model:filters="filters1"
            :value="datosComparativosMxMipres"
            showGridlines class="p-datatable-sm"
            tableStyle="min-width: 50rem"
            :globalFilterFields="['codmx', 'codmipres']"
            filterDisplay="row"
          >
            <Column field="codmx" header="Cod MX">
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback(), paginarTabla()" class="p-column-filter" />
              </template>
            </Column>
            <Column field="codmipres" header="Cod MIPRES">
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offset"
            :rows="limit"
            :totalRecords="totalItems"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPage($event)" />
        </TabPanel>
        <TabPanel header="Datos página sispro.gov">
          <div class="my-4">
            <p class="font-medium italic">Fecha de la ultima actualización {{ datosSispro.length ? dayjs(datosSispro[0].createdAt).format('DD-MM-YYYY HH:mm A') : '' }}</p>
          </div>
          <DataTable
            v-model:filters="filtersSispro"
            :value="datosSispro"
            showGridlines
            class="p-datatable-sm"
            tableStyle="min-width: 50rem"
            filterDisplay="row"
            :globalFilterFields="['expediente', 'dci', 'forma_farmaceutica']"
          >
            <Column field="expediente" header="Expediente">
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback(), paginarTablaSispro()" class="p-column-filter" />
              </template>
            </Column>
            <Column field="dci" header="DCI- Concentracion">
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
              </template>
            </Column>
            <Column field="forma_farmaceutica" header="Forma- Farmaceutica">
              <template #filter="{ filterModel, filterCallback }">
                <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter" />
              </template>
            </Column>
          </DataTable>
          <Paginator v-model:first="offsetSispro"
            :rows="limitSispro"
            :totalRecords="totalItemsSispro"
            :rowsPerPageOptions="[2,10,20,30,100]"
            @page="onPageSispro($event)" />
        </TabPanel>
      </TabView>
      <Dialog header="Agregar soportes" v-model:visible="displayFilePond" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
        <div>
          <Dropdown v-model="tipoArchivoSeleccionado" :options="tiposArchivos" placeholder="Tipo archivo" class="w-full md:w-14rem mb-2" />
          <FileUpload
            mode="basic"
            name="demo[]"
            url="./upload.php"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :maxFileSize="1000000"
            @change="subirArchivo()"
            ref="file"
          />
        </div>
        <template class="text-center" #footer>
          <Button label="Guardar" class="w-full" @click="guardarArchivo" autofocus />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script>
  import { onMounted, ref, computed } from 'vue'
  import { useToast } from 'primevue/usetoast'
  import { useMipresStore } from '../../../../../stores/mipres.store'
  import { FilterMatchMode } from 'primevue/api'
  import XLSX from 'xlsx'
  import dayjs from 'dayjs'
  import Swal from 'sweetalert2'
  export default {
    name: 'configuracionMipresCargueDocumentos',
    setup () {
      const toast = useToast()
      const mipresStore = useMipresStore()
      const displayFilePond = ref(false)
      const files = ref([])
      const file = ref()
      const dataCrear = ref([])
      const limit = ref(10)
      const offset = ref(0)
      const totalItems = ref(0)
      const limitSispro = ref(10)
      const offsetSispro = ref(0)
      const totalItemsSispro = ref(0)
      const datosSispro = ref([])
      const datosComparativosMxMipres = ref([])
      const tiposArchivos = ref(['Sispro', 'Relacion codigo mipres con cod mx'])
      const tipoArchivoSeleccionado = ref('')
      const filters1 = ref({
          codmx: { value: null, matchMode: FilterMatchMode.CONTAINS },
          codmipres: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const filtersSispro = ref({
          expediente: { value: null, matchMode: FilterMatchMode.CONTAINS },
          dci: { value: null, matchMode: FilterMatchMode.CONTAINS },
          forma_farmaceutica: { value: null, matchMode: FilterMatchMode.CONTAINS }
      })
      const params = computed(() => {
        return {
          limit: limit.value,
          offset: offset.value
        }
      })
      const paramsSispro = computed(() => {
        return {
          limit: limitSispro.value,
          offset: offsetSispro.value
        }
      })
      // Methods
      const guardarArchivo = () => {
        if (!tipoArchivoSeleccionado.value) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes completar todos los campos para continuar', life: 3000 })
        if (!dataCrear.value.length) return toast.add({ severity: 'error', summary: 'Error', detail: 'Debes ingresar al menos 1 registro en el excel', life: 3000 })
        if (tipoArchivoSeleccionado.value === 'Relacion codigo mipres con cod mx') {
          mipresStore.actualizarRelacionMipresCodmxCodmipres(dataCrear.value).then((data) => {
            if (data) {
              Swal.fire({
                title: 'Guardado',
                text: `Archivo ${tipoArchivoSeleccionado.value} actualizado con éxito`,
                icon: 'success'
              })
            }
          })
        }
        if (tipoArchivoSeleccionado.value === 'Sispro') {
          mipresStore.actualizarRelacionMipresSispro(dataCrear.value).then((data) => {
            if (data) {
              Swal.fire({
                title: 'Guardado',
                text: `Archivo ${tipoArchivoSeleccionado.value} actualizado con éxito`,
                icon: 'success'
              })
            }
          })
        }
      }
      const subirArchivo = () => {
        const archivo = file.value.files[0]
        if (archivo) {
          const reader = new FileReader()
          reader.onload = (event) => {
            const result = event.target.result
            const dataOne = new Uint8Array(result)
            const workbook = XLSX.read(dataOne, { type: 'array', cellDates: true })
            const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]
            const data = XLSX.utils.sheet_to_json(firstWorksheet, { header: 2, raw: false, dateNf: dayjs('DD/MM/YYYY').format('YYYY-MM-DD') })
            dataCrear.value = data
          }
          reader.readAsArrayBuffer(archivo)
        }
      }
      const onPage = ({ first, page, pageCount, rows }) => {
        limit.value = rows
        paginarTabla()
      }
      const onPageSispro = ({ first, page, pageCount, rows }) => {
        limitSispro.value = rows
        paginarTablaSispro()
      }
      const paginarTabla = () => {
        const filtros = {}
        for (const [key, result] of Object.entries(filters1.value)) {
          if (result.value) {
            filtros[key] = result.value
          }
        }
        const object = { ...params.value }
        if (Object.values(filtros).length) {
          object.filtros = filtros
        }
        mipresStore.paginarRelacionCodmxCodmipres(object).then((data) => {
          totalItems.value = data.count
          datosComparativosMxMipres.value = data.rows
        })
      }
      const paginarTablaSispro = () => {
        const filtros = {}
        for (const [key, result] of Object.entries(filtersSispro.value)) {
          if (result.value) {
            filtros[key] = result.value
          }
        }
        const object = { ...paramsSispro.value }
        if (Object.values(filtros).length) {
          object.filtros = filtros
        }
        mipresStore.paginarRelacionMipresSispro(object).then((data) => {
          totalItemsSispro.value = data.count
          datosSispro.value = data.rows
        })
      }
      onMounted(() => {
        paginarTabla()
        paginarTablaSispro()
      })
      return {
        displayFilePond,
        files,
        guardarArchivo,
        datosSispro,
        tiposArchivos,
        tipoArchivoSeleccionado,
        datosComparativosMxMipres,
        file,
        subirArchivo,
        totalItems,
        limit,
        dayjs,
        onPage,
        offset,
        filters1,
        paginarTabla,
        filtersSispro,
        limitSispro,
        offsetSispro,
        paginarTablaSispro,
        totalItemsSispro,
        onPageSispro
      }
    }
  }
</script>
<style scoped>
</style>
